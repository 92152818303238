import React from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import {useUserStore} from "../store/userStore";

const SiteHeader = () => {
  // let location = useLocation();
  // const { user, setUser, emptyUser } = useUserStore();

//   return user ? <HeaderLogged /> : <Header />;
  return <HeaderLogged />;
};

export default SiteHeader;
