import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
}
function formatPrice(price: number) {
    // Convert the price to a string with fixed two decimal places
    let priceStr = price.toFixed(2);

    // Split the string into the whole number and decimal parts
    let parts = priceStr.split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1];

    // Add spaces for thousands
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Combine the parts with a comma as the decimal delimiter
    return formattedIntegerPart + ',' + decimalPart;
}
const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}
      >
        <span className="text-green-500 !leading-none">
          {formatPrice(price)}€
        </span>
      </div>
    </div>
  );
};

export default Prices;
