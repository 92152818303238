import React, {useEffect, useState} from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import axios from "axios";

// DEMO DATA
const DATA_categories = [
  {
    name: "Tričká",
  },
  {
    name: "Mikiny",
  },
  {
    name: "Hrnčeky",
  },
  {
    name: "BJK Fotoboxy",
  },
  {
    name: "Tašky",
  },
  {
    name: "Vrecúška",
  },
{
    name: "Batohy",
  },
];

const DATA_colors = [
  { name: "Biela" },
  { name: "Čierna" },
  { name: "Červená" },
  { name: "Hnedá" },
  { name: "Ružová" },
  { name: "Zelená" },
  { name: "Modrá" },
];

const DATA_sizes = [
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_sortOrderRadios = [

  { name: "Najnovšie", id: "Newest" },
  { name: "Od najlacnejšieho", id: "Price-low-hight" },
  { name: "Od najdrahšieho", id: "Price-hight-low" },
];

const PRICE_RANGE = [1, 500];
//
const SidebarFilters = () => {
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([100, 500]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const [categoryTags,setCategoryTags]=useState([1,3,5])
  /*
  const [productsAreLoading,setProductsAreLoading]=useState(false)
  const [products,setProducts]=useState([])
  useEffect(()=> {
    if(!productsAreLoading)loadProducts()

  },[categoriesState])
  const loadProducts = () => {
    setProductsAreLoading(true)
    let headers={}
    const params = {
      'action': 'get_products',
      'from': 1,
      'count': 20,
      'props':categoriesState
    }
    axios.get('https://mojavec.store/api/v1/get/', {
      headers,
      params
    })
        .then((response => {
          console.log('get_products response: ',response)
          if (response.data.data) {
            const data = response.data.data;
            const meta = response.data.meta;
            if (data.products) {
              setProducts(data.products)
            }
          }else
          if (response.data.error) {
            const error = response.data.error;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }

          setProductsAreLoading(false)

        }))
        .catch(error => {
          setProductsAreLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
  } */

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  //

  // OK
  const renderTabsCategories = () => {
    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        <h3 className="font-semibold mb-2.5 mt-10">Produkty</h3>
        {DATA_categories.map((item) => (
          <div key={item.name} className="">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={categoriesState.includes(item.name)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(checked) => handleChangeCategories(checked, item.name)}
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsColor = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Farby</h3>
        {DATA_colors.map((item) => (
          <div key={item.name} className="">
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              name={item.name}
              label={item.name}
              defaultChecked={colorsState.includes(item.name)}
              onChange={(checked) => handleChangeColors(checked, item.name)}
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsSize = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Veľkosti</h3>
        {DATA_sizes.map((item) => (
          <div key={item.name} className="">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={sizesState.includes(item.name)}
              onChange={(checked) => handleChangeSizes(checked, item.name)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Price range</span>
          <Slider
            range
            min={PRICE_RANGE[0]}
            max={PRICE_RANGE[1]}
            step={1}
            defaultValue={[rangePrices[0], rangePrices[1]]}
            allowCross={false}
            onChange={(_input: number | number[]) =>
              setRangePrices(_input as number[])
            }
          />
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                $
              </span>
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                $
              </span>
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Zoradiť</h3>
        {DATA_sortOrderRadios.map((item) => (
          <Radio
            id={item.id}
            key={item.id}
            name="radioNameSort"
            label={item.name}
            defaultChecked={sortOrderStates === item.id}
            sizeClassName="w-5 h-5"
            onChange={setSortOrderStates}
            className="!text-sm"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="divide-y divide-slate-200 dark:divide-slate-700">
      {renderTabsCategories()}
      {/* renderTabsColor() */}
      {/* renderTabsSize() */}
      {/*renderTabsPriceRage()*/}
      {/*<div className="py-8 pr-2">
        <MySwitch
          label="On sale!"
          desc="Products currently on sale"
          enabled={isOnSale}
          onChange={setIsIsOnSale}
        />
      </div>*/}
      {/* renderTabsSortOrder() */}
    </div>
  );
};

export default SidebarFilters;
