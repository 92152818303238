import create from 'zustand';

const useEshop = create((set) => ({
    settings: {
        mainUrl: 'https://mojavec.store/',
        theme: 'light',
        language: 'sk',
        currency: 'EUR',
        deliveryFreeFrom:35, //if 0,no free delivery
        deliveryOptions: [
            { id: 0, name: 'Slovenská pošta, 1.trieda', cost: 2.90 },
            { id: 1, name: 'GLS kuriér', cost: 3.90 },
            { id: 2, name: 'Osobný odber', cost: 0 }
            // Add more delivery options as needed
        ],
        paymentOptions: [
            { id: 0, name: 'Bankový prevod, platba vopred', cost: 0 },
            { id: 1, name: 'Dobierka', cost: 1 }

            ],
        maleSizes: [ "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
        femaleSizes: ["XS", "S", "M", "L", "XL","2XL","3XL"],
        allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
    },
    setSettings: (newSettings) => set((state) => ({ settings: { ...state.settings, ...newSettings } }))
}));

export default useEshop;