import create from 'zustand';

export const useUserStore = create((set) => ({
      user: null,
     /* user: {
        id: '1',
        name: 'John Doe',
        email: 'john.doe@example.com',
    },*/
    setUser: (user) => set({ user }),
    emptyUser: () => set({ user: null }),
}));


/*

 const handleLogin = () => {
    // Example user data
    const userData = {
      id: '1',
      name: 'John Doe',
      email: 'john.doe@example.com',
    };
    setUser(userData);
  };

  const handleLogout = () => {
    emptyUser();
  };


 */