import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";
import Tag from "shared/Tag/Tag";

const Vop = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Všeobecné obchodné podmienky"
          >
            Všeobecné obchodné podmienky
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            We’re an online magazine dedicated to covering the best in
            international product design. We started as a little blog back in
            2002 covering student work and over time
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="##">
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
        <div
            id="single-entry-content"
            className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
        >
          <h1>Všeobecné obchodné podmienky</h1>
          <p><strong>BJK Foto s.r.o.,</strong><br/> Svätotrojičné nám. 19, 963 01 Krupina, <br />IČO: 47 328 924, <br />IČ DPH: SK2023819996 <br />Spoločnosť zapísaná v Obchodnom registri Okresného súdu Banská Bystrica, oddiel: Sro, vložka č.: 24530/S (ďalej len „VOP“).</p>

          <h2>1. Všeobecné ustanovenia</h2>
          <ol>
            <li>
              Tieto VOP sa vzťahujú na zmluvné vzťahy, ktoré vzniknú medzi predávajúcim a kupujúcim uzatvorením kúpnej zmluvy a tvoria súčasť každej kúpnej zmluvy uzatvorenej medzi zmluvnými stranami. Kupujúcim môžu byť tak fyzické osoby, ako aj
              právnické osoby.
            </li>
            <li>
              Vyplnením objednávkového formulára „Objednávka“ a potvrdením oboznámenia sa s týmito VOP (ďalej len „objednávka“), kupujúci pristupuje k týmto VOP predávajúceho, ktoré tvoria neoddeliteľnú súčasť každej kúpnej zmluvy a sú stále
              prístupné na webovej stránke predávajúceho.
            </li>
            <li>
              Predávajúci je aj prevádzkovateľom systému elektronického obchodu prevádzkovaného prostredníctvom internetovej stránky predávajúceho (ďalej len „obchod“).
            </li>
            <li>
              Na zmluvné vzťahy (ako aj ďalšie právne vzťahy, ktoré môžu zo zmluvného vzťahu vyplynúť) s fyzickými osobami, ktoré pri uzatváraní kúpnej zmluvy podľa týchto VOP nekonajú v rámci predmetu svojej podnikateľskej činnosti, sa vzťahujú
              okrem všeobecných ustanovení zákona č. 40/1964 Zb. Občiansky zákonník v znení neskorších predpisov, aj osobitné predpisy, najmä zákon č. 102/2014 Z. z. o ochrane spotrebiteľa pre predaji tovaru alebo poskytnutí služieb na základe zmluvy
              uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho a zákon č. 250/2007 Z.z. o ochrane spotrebiteľa.
            </li>
            <li>
              Na zmluvné vzťahy (ako aj ďalšie právne vzťahy, ktoré môžu zo zmluvného vzťahu vyplynúť) s právnickými osobami, resp. s fyzickými osobami – podnikateľmi, sa vzťahujú ustanovenia zákona č. 513/1991 Zb. Obchodný zákonník v platnom znení.
            </li>
            <li>
              Kupujúcim je každá osoba (fyzická alebo právnická), ktorá vyplnila a odoslala objednávku prostredníctvom webovej stránky predávajúceho, obdržala emailové oznámenie o prijatí objednávky a uhradila kúpnu cenu. Kupujúcim je taktiež každá
              osoba, ktorá zadala objednávku telefonicky, alebo zaslaním emailu a emailom potvrdila akceptovanie cenovej ponuky predávajúceho a VOP.
            </li>
            <li>
              Objednávkou sa rozumie kupujúcim vyplnený a odoslaný objednávkový formulár „Objednávka“ spracovaný systémom obchodu. V prípade, ak kupujúci nevyužije možnosť objednania prostredníctvom objednávkového formulára v zmysle predchádzajúcej
              vety, za objednávku sa považuje aj telefonická objednávka, alebo objednávka zaslaná emailom, alebo poštou na adresu predávajúceho.
            </li>
            <li>
              Objednávka obsahuje informácie o zákazníkovi a o objednanom produkte. Na základe objednávky sa zákazníkovi vygeneruje automatický email so všetkými potrebnými informáciami o objednaných produktoch, ich úhrade a aj odoslaní samotnej
              objednávky. Všetky uvedené informácie mu budú následne odoslané na email, ktorý zákazník vyplnil. V prípade, ak kupujúci nevyužije možnosť objednania prostredníctvom objednávkového formulára, ale vykoná objednávku telefonicky, alebo
              zaslaním emailu, alebo poštou na adresu predávajúceho, predávajúci zašle kupujúcemu cenovú ponuku emailom.
            </li>
            <li>
              Produktom sa rozumie: Potlačené textílie, DTF fólie, hrnčeky, krabičky, batohy, šiltovky, mikiny, polokošele, košele, vesty, tričká, podložky pod myš a podobné potlačiteľné produkty. Hlavnými vlastnosťami produktu je profesionálna
              kvalita a dlhotrvajúca stálosť potlače.
            </li>
          </ol>

          <h2>2. Objednávka produktu – uzatvorenie kúpnej zmluvy</h2>
          <ol>
            <li>
              Elektronická objednávka produktov uskutočnená formou vyplneného objednávkového formulára „Objednávka“ a následná úhrada kúpnej ceny v zmysle automaticky vygenerovaného emailu so všetkými potrebnými informáciami, o objednaných
              produktoch, ich úhrade a aj odoslaní samotnej objednávky, je návrhom na uzavretie kúpnej zmluvy zo strany kupujúceho. Kúpna cena sa považuje za uhradenú dňom pripísania peňažných prostriedkov na účet predávajúceho.
            </li>
            <li>
              K akceptácii objednávky produktov zo strany predávajúceho, a teda k uzatvoreniu kúpnej zmluvy dochádza na základe písomného potvrdenia (elektronicky) prijatia objednávky a potvrdenia o pripísaní kúpnej ceny na účet predávajúceho, pričom
              predávajúci zároveň informuje kupujúceho, že produkt bol zadaný do výroby/na odoslanie.
            </li>
            <li>
              Návrhom na uzatvorenie kúpnej zmluvy sa rozumie rovnako zaslanie cenovej ponuky vyhotovenej predávajúcim na základe telefonickej alebo emailovej objednávky kupujúceho. K uzatvoreniu kúpnej zmluvy dochádza v tomto prípade písomným
              potvrdením (elektronicky) cenovej ponuky kupujúcim.
            </li>
            <li>
              V prípade objednávky prostredníctvom objednávkového formulára „Objednávka“, je kupujúci povinný zaplatiť predávajúcemu kúpnu cenu vždy vopred, v opačnom prípade nedôjde k uzatvoreniu kúpnej zmluvy.
            </li>
            <li>
              V prípade ak je objednávka vybavovaná s platbou na dobierku, alebo faktúrou, v zmysle bodu 3 tohto článku VOP, je kupujúci povinný zaplatiť kúpnu cenu na základe faktúry vystavenej a doručenej predávajúcim kupujúcemu. V prípade
              omeškania kupujúceho s úhradou kúpnej ceny, má predávajúci nárok požadovať zaplatenie zmluvnej pokuty vo výške 0,05% z dlžnej sumy za každý aj začatý deň omeškania.
            </li>
            <li>
              Kúpna zmluva sa uzatvára na dobu určitú a zaniká splnením záväzkov predávajúceho a kupujúceho.
            </li>
          </ol>

          <h2>3. Cena produktu</h2>
          <ol>
            <li>
              Cena produktov je uvedená v obchodnom systéme predávajúceho a je platná v momente objednania produktu kupujúcim.
            </li>
            <li>
              Predávajúci si vyhradzuje právo na zmenu ceny produktov uvedených v obchodnom systéme predávajúceho v prípade zmeny právnych predpisov, ako aj v prípade zmeny cien výrobcov alebo dodávateľov produktov.
            </li>
            <li>
              Cena produktov nezahŕňa náklady na dodanie produktu kupujúcemu, ak nie je výslovne uvedené inak.
            </li>
            <li>
              Celková cena objednávky, vrátane kúpnej ceny produktov a nákladov na dodanie produktu kupujúcemu, bude uvedená v objednávkovom formulári „Objednávka“ a v automaticky vygenerovanom emailovom oznámení.
            </li>
            <li>
              Predávajúci sa zaväzuje dodať produkt za cenu, ktorá bola uvedená v objednávkovom formulári „Objednávka“ v momente odoslania objednávky.
            </li>
          </ol>

          <h2>4. Platobné podmienky</h2>
          <ol>
            <li>
              Kupujúci je povinný uhradiť kúpnu cenu produktu v plnej výške pred dodaním produktu, ak nie je dohodnuté inak.
            </li>
            <li>
              Platba sa považuje za vykonanú dňom pripísania peňažných prostriedkov na účet predávajúceho, ak nie je dohodnuté inak.
            </li>
            <li>
              Kupujúci môže kúpnu cenu uhradiť prostredníctvom bankového prevodu, platobnou kartou, alebo iným spôsobom dohodnutým s predávajúcim.
            </li>
            <li>
              V prípade omeškania kupujúceho s úhradou kúpnej ceny má predávajúci nárok požadovať zaplatenie zmluvnej pokuty vo výške 0,05% z dlžnej sumy za každý aj začatý deň omeškania.
            </li>
          </ol>

          <h2>5. Dodacie podmienky</h2>
          <ol>
            <li>
              Predávajúci sa zaväzuje dodať produkt kupujúcemu v lehote uvedenej v potvrdení objednávky, ak sa zmluvné strany nedohodli inak.
            </li>
            <li>
              Predávajúci sa zaväzuje dodať produkt kupujúcemu na miesto dodania uvedené v objednávkovom formulári „Objednávka“.
            </li>
            <li>
              Kupujúci je povinný prevziať objednaný produkt v mieste dodania uvedenom v objednávkovom formulári „Objednávka“.
            </li>
            <li>
              Predávajúci nezodpovedá za omeškanie dodania produktu spôsobené dopravcom alebo nesprávne uvedenými údajmi zo strany kupujúceho.
            </li>
            <li>
              V prípade, ak kupujúci odmietne prevziať objednaný produkt, predávajúci má nárok na úhradu nákladov spojených s opätovným doručením produktu kupujúcemu.
            </li>
          </ol>

          <h2>6. Odstúpenie od zmluvy</h2>
          <ol>
            <li>
              Kupujúci má právo odstúpiť od zmluvy do 14 dní od prevzatia produktu bez udania dôvodu. Na účely uplatnenia práva na odstúpenie od zmluvy musí kupujúci informovať predávajúceho o svojom rozhodnutí odstúpiť od zmluvy jednoznačným
              vyhlásením (napríklad listom zaslaným poštou, faxom alebo e-mailom).
            </li>
            <li>
              V prípade odstúpenia od zmluvy kupujúcim, predávajúci vráti kupujúcemu všetky platby, ktoré kupujúci uhradil v súvislosti s uzavretím kúpnej zmluvy, vrátane nákladov na dodanie, a to do 14 dní odo dňa, keď bol predávajúci informovaný o
              rozhodnutí kupujúceho uplatniť právo na odstúpenie od zmluvy. Vrátenie platby bude uskutočnené rovnakým spôsobom, aký kupujúci použil pri pôvodnej transakcii, pokiaľ sa kupujúci výslovne nedohodol inak; v každom prípade tým kupujúcemu
              nevzniknú žiadne ďalšie náklady.
            </li>
            <li>
              V prípade odstúpenia od zmluvy kupujúcim je kupujúci povinný zaslať produkt späť predávajúcemu na adresu BJK Foto s.r.o., Svätotrojičné nám. 19, 963 01 Krupina, a to bez zbytočného odkladu, najneskôr však do 14 dní odo dňa, keď kupujúci
              informoval predávajúceho o odstúpení od tejto zmluvy. Lehota je dodržaná, ak kupujúci odošle produkt späť pred uplynutím 14-dňovej lehoty.
            </li>
            <li>
              Kupujúci znáša priame náklady na vrátenie produktu.
            </li>
          </ol>

          <h2>7. Zodpovednosť za vady a reklamácie</h2>
          <ol>
            <li>
              Predávajúci zodpovedá za vady, ktoré má produkt pri prevzatí kupujúcim, ako aj za vady, ktoré sa vyskytnú po prevzatí produktu v záručnej dobe.
            </li>
            <li>
              Kupujúci je povinný pri prevzatí produktu skontrolovať jeho stav a bez zbytočného odkladu informovať predávajúceho o zistených vadách.
            </li>
            <li>
              Kupujúci má právo uplatniť reklamáciu písomne na adrese BJK Foto s.r.o., Svätotrojičné nám. 19, 963 01 Krupina, alebo e-mailom na adrese info@bjkfoto.sk.
            </li>
            <li>
              Predávajúci sa zaväzuje vybaviť reklamáciu do 30 dní od jej prijatia a informovať kupujúceho o výsledku reklamácie.
            </li>
            <li>
              V prípade oprávnenej reklamácie má kupujúci právo na bezplatné odstránenie vady produktu alebo na výmenu produktu za nový. Ak to nie je možné, kupujúci má právo na primeranú zľavu z ceny produktu alebo na odstúpenie od zmluvy.
            </li>
          </ol>

          <h2>8. Ochrana osobných údajov</h2>
          <ol>
            <li>
              Predávajúci spracúva osobné údaje kupujúceho v súlade so zákonom č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov.
            </li>
            <li>
              Kupujúci vyjadruje súhlas so spracovaním jeho osobných údajov na účely plnenia kúpnej zmluvy a na účely marketingových aktivít predávajúceho.
            </li>
            <li>
              Kupujúci má právo na prístup k svojim osobným údajom, právo na ich opravu, ako aj právo požiadať o ich vymazanie.
            </li>
          </ol>

          <h2>9. Záverečné ustanovenia</h2>
          <ol>
            <li>
              Tieto VOP nadobúdajú platnosť a účinnosť dňom ich zverejnenia na webovej stránke predávajúceho.
            </li>
            <li>
              Predávajúci si vyhradzuje právo na zmenu týchto VOP. Povinnosť písomného oznámenia zmeny VOP je splnená umiestnením na webovej stránke predávajúceho.
            </li>
            <li>
              V prípade, že sa niektoré ustanovenie týchto VOP stane neplatným alebo neúčinným, ostatné ustanovenia tým nie sú dotknuté.
            </li>
            <li>
              Tieto VOP sú vyhotovené v slovenskom jazyku. V prípade, že vznikne rozpor medzi slovenskou verziou a prekladom týchto VOP, je rozhodujúca slovenská verzia.
            </li>
          </ol>

        </div>
    );
  };

  return (
      <div className="nc-PageSingle pt-8 lg:pt-16 ">
        <Helmet>
          <title>Všeobecné obchodné podmienky || MojaVec</title>
        </Helmet>


        <div className="nc-SingleContent container space-y-10">
          {renderContent()}
        </div>

      </div>
  );
};

export default Vop;
