import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import {Transition} from "@headlessui/react";
import TabFilters from "../components/TabFilters";
import SidebarFilters from "./SidebarFilters";
import Nav from "../shared/Nav/Nav";
import NavItem2 from "../components/NavItem2";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
import axios from "axios";
import Checkbox from "../shared/Checkbox/Checkbox";
import useCartStore from "../store/cartStore";
import useEshop from "../store/eshopStore";

export interface ProductsSearchListProps {
  className?: string;
}

const ProductsSearchList: FC<ProductsSearchListProps> = ({ className = "" }) => {
  const [productsData, setProductsData]=useState([{name:"sdfs"}])
  const [tabActive, setTabActive] = React.useState("Man");
    const { categorySlug } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const size = queryParams.get('size');
  const motives = queryParams.get('motives');
  const cat = queryParams.get('cat');
  const [fixedAttributes,setFixedAttributes]=useState([100,99,165]);//motivy, farba
  const [productCategoryToLoad,setProductCategoryToLoad]=useState('all')
  const [oldCategory,setOldCategory]=useState('')
  const [searchPhrase,setSearchPhrase]=useState('')
  const [catInfo,setCatInfo]=useState(null)
  const navigate = useNavigate();
  const [productsAreLoading,setProductsAreLoading]=useState(false)
  const [showEmptyCategoriesInFilters,setShowEmptyCategoriesInFilters]=useState(false)
  const [products,setProducts]=useState([])
  const [fixedCategoryAttributesValues,setFixedCategoryAttributesValues]=useState([])
  const [fixedCategoryAttributes,setFixedCategoryAttributes]=useState([])
  const [categoryAttributesValues,setCategoryAttributesValues]=useState([])
  const [categoryAttributes,setCategoryAttributes]=useState([])
  const [subFilters,setSubFilters]=useState([])
  const [productCategories,setProductCategories]=useState(null)
  const [productMotives,setProductMotives]=useState(null)
  const [loadedProductsMeta,setLoadedProductsMeta]=useState(null)
  const [filterStates, setFilterStates] = useState<string[]>([]);
  const [filtersAreChanged,setFiltersAreChanged]=useState(false)
  const [catsAreLoading,setCatsAreLoading]=useState(false)
  const [filterSubOptionsAreLoading,setFilterSubOptionsAreLoading]=useState(false)
  let typTimer: NodeJS.Timeout | null=null

    // Get the value of the 'cat' parameter

    const cart = useCartStore((state) => state.cart);
    const addItem = useCartStore((state) => state.addItem);
    const removeItem = useCartStore((state) => state.removeItem);
    const clearCart = useCartStore((state) => state.clearCart);
    const eshop = useEshop((state) => state.settings);


    const clearUnnecessaryStates = (fixedCatValuesFromApi:[],catValuesFromApi:[]) =>{
        /* console.log('going to clearUnnecessaryStates')
        console.log(Array.isArray(catsFromApi));
        console.log('Current filterstates: ',filterStates)
        console.log('CatValues from Api: ',catsFromApi)
        */

        // @ts-ignore

        const allIds = [];

// Iterate through the object properties (categories)
        for (const categoryKey in fixedCatValuesFromApi) {
            if (fixedCatValuesFromApi.hasOwnProperty(categoryKey)) {
                // Iterate through the array of objects in each category
                const categoryArray = fixedCatValuesFromApi[categoryKey];
                // @ts-ignore
                for (const object of categoryArray) {
                    // Extract and store the "id" value
                    allIds.push(object.id);
                }
            }
        }


// Iterate through the object properties (categories)
        for (const categoryKey in catValuesFromApi) {
            if (catValuesFromApi.hasOwnProperty(categoryKey)) {
                // Iterate through the array of objects in each category
                const categoryArray = catValuesFromApi[categoryKey];
                // @ts-ignore
                for (const object of categoryArray) {
                    // Extract and store the "id" value
                    allIds.push(object.id);
                }
            }
        }



// @ts-ignore

        //console.log('Final propIdsArr: ',allIds)
        // @ts-ignore
        const updatedFilterStates = filterStates.filter((value) => allIds.includes(value));
        // console.log('vsetky IDcka novych vlastnosti: ',allIds)
        // console.log('filter states: ',filterStates)

//        console.log('Pole updatedFilterStates po prieniku',updatedFilterStates)
        setFilterStates(updatedFilterStates);
    }

    const addIdToFilterStates = (newId: string) => {
        console.log('adding newId:',newId)
        setFilterStates((prevFilterStates) => {
            // Check if the newId already exists in the array
            if (!prevFilterStates.includes(newId)) {
                // Add the newId to the array
                return [...prevFilterStates, newId];
                console.log('adding newId2:',newId)
            }
            // Return the array unchanged if the newId is already present
            return prevFilterStates;
        });
    };


  const loadProducts = (searchterm=null,useSearchPhraseFromState=true) => {
      //if i dont' want to include search term in searching,
          // use(loadProducts(null, false))
      //if from searcg input (use(loadProducts(null, true))
      //if directly (use(loadProducts('term to search', false))
      // addIdToFilterStates("173")
    setProductsAreLoading(true)
    let headers={}
      if(useSearchPhraseFromState){ // @ts-ignore
          searchterm=searchPhrase;
      }
    const params = {
      'action': 'get_products',
      'from': 1,
      'count': 20,
      'category': categorySlug,
      'old_cat': oldCategory,
      'search': searchterm,
      'fixed_attributes': fixedAttributes,
      'show_empty_categories': showEmptyCategoriesInFilters,
      'props':cat?[...filterStates, cat]:filterStates,


    }
    axios.get('https://mojavec.store/api/v1/get/', {
      headers,
      params
    })
        .then((response => {
          // console.log('get_products response: ',response)
          if (response.data.data) {
            const data = response.data.data;
            const meta = response.data.meta;
            if (data.catInfo) {
              setTabActive(data.catInfo.name)
              setCatInfo(data.catInfo)
              setOldCategory(data.catInfo.slug)
            }
            if (data.products) {
                const updatedProducts = data.products.map((product: { tags: string | string[]; count: number; color: string; size: string; type: string; is_hashed_path: string;image: string; created_at_hard: string; productImgPath?: string; selectedCut?: string; cutFemale?: boolean; cutMale?: boolean; cutUnisex?: boolean; }) => {
                    let productType = '';
                    if (product.tags.includes("98")) productType = 'tShirt'; //tricko
                    if (product.tags.includes("127")) productType = 'sweatShirt'; //mikina

                    let productImgPath='';
                    if (product.is_hashed_path==="1") {
                        productImgPath = eshop.mainUrl + 'web_files/' + product.image;
                    } else {
                        const imgDate = new Date(product.created_at_hard);
                        let imgYear = imgDate.getFullYear();
                        let imgMonth = String(imgDate.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
                        productImgPath = eshop.mainUrl + 'web_files/' + imgYear + '/' + imgMonth + '/' + product.image;
                    }

                    const getInitialCutValue = (
                        product: { tags: string | string[]; }) => {
                        if (product.tags.includes("153")) return 'damsky_strih';
                        if (product.tags.includes("152")) return 'pansky_strih';
                        if (product.tags.includes("174")) return 'unisex_strih';
                        return ''; // or some default value
                    };

                    product.selectedCut= getInitialCutValue(product)
                    product.productImgPath = productImgPath
                    product.count = 1
                    product.color = ''
                    product.size =(productType==='tShirt'||productType==='sweatShirt')? 'L':''
                    product.cutFemale= (product.tags.includes("153")) ? true : false
                    product.cutMale= (product.tags.includes("152")) ? true : false
                    product.cutUnisex= (product.tags.includes("174")) ? true : false
                    product.type = productType
                    return product;
                });

                setProducts(updatedProducts);
                // setProducts(data.products)
            }
            if (data.meta) setLoadedProductsMeta(data.meta)
              if (data.categories)setProductCategories(data.categories)
              if (data.category_attributes) setCategoryAttributes(data.category_attributes)
              if (data.category_attribute_values) {

                  setCategoryAttributesValues(data.category_attribute_values)
              }
              if (data.fixed_category_attributes) setFixedCategoryAttributes(data.fixed_category_attributes)


              if (data.fixed_category_attribute_values) {
                  setFixedCategoryAttributesValues(data.fixed_category_attribute_values)
              }
              clearUnnecessaryStates(data.fixed_category_attribute_values,data.category_attribute_values)


          }else
          if (response.data.error) {
            const error = response.data.error;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }else{setProducts([])}

          setProductsAreLoading(false)

        }))
        .catch(error => {
          setProductsAreLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
  }
  /*
  const loadFilterSubOptions = () => {
    setFilterSubOptionsAreLoading(true)
    let headers={}
    const params = {
      'action': 'get_filters_sub_options',
      'category': categorySlug,
      // 'props':categoriesState
    }
    axios.get('https://mojavec.store/api/v1/get/', {
      headers,
      params
    })
        .then((response => {
          console.log('get subfilter options response: ',response)
          if (response.data.data) {
            const data = response.data.data;
            const meta = response.data.meta;

              setCategoryAttributes(data.category_attributes)
              setCategoryAttributesValues(data.category_attribute_values)

          }else
          if (response.data.error) {
            const error = response.data.error;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }

          setFilterSubOptionsAreLoading(false)

        }))
        .catch(error => {
          setFilterSubOptionsAreLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
  }
  */
  const handleProductsData = (data: any) => {
    setProductsData(data);
    // console.log('data received from child:',data)
  };
/*
  useEffect(()=> {
       renderFixedSubFilters()
       renderSubFilters ()
  },[filterStates])
*/
  useEffect(()=> {
    // console.log('categorySlug:',categorySlug);
    if(categorySlug&&!productsAreLoading)loadProducts(null, true)
    // if(categorySlug&&!filterSubOptionsAreLoading)loadFilterSubOptions()
  },[categorySlug])

/*
  useEffect(()=> {
    console.log('categoryAttributes:',categoryAttributes);
    console.log('categoryAttributesValues:',categoryAttributesValues);
  },[categoryAttributes])
*/
  useEffect(()=> {
    console.log('filtersAreChanged:',filtersAreChanged);
    console.log('filterStates:',filterStates);
    console.log('filtersAreChanged:',filtersAreChanged);
    if(categorySlug&&!productsAreLoading&&filtersAreChanged) {
        console.log('going to loadproducts');

        loadProducts(null, true)
        setFiltersAreChanged(false)
        renderFixedSubFilters()
        renderSubFilters()
    }
  },[filtersAreChanged])

  /* useEffect(()=> {
    if (!catsAreLoading) loadCats()
  },[]) */


  const loadCats = () => {
    // console.log('loading categories and motives...')
    setCatsAreLoading(true)
    let headers={}
    const params = {
      'action': 'get_categories_and_motives',
    }
    axios.get('https://mojavec.store/api/v1/get/', {
      headers,
      params
    })
        .then((response => {
          console.log('get_filters get_categories_and_motives: ',response)
          if (response.data.data) {
            const data = response.data.data;
            const meta = response.data.meta;
            if (data) {
              // if (data.categories)setProductCategories(data.categories)
              if (data.motives)setProductMotives(data.motives)
            }
          }else
          if (response.data.error) {
            const error = response.data.error;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }

          setCatsAreLoading(false)

        }))
        .catch(error => {
          setCatsAreLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
  }
  const handleChangeFilters = (checked: boolean, id: string) => {
      console.log('handleChangeFilters.checked:',checked)
      console.log('handleChangeFilters.id:',id)
      console.log('filtersAreChanged:',filtersAreChanged);
      setFiltersAreChanged(true)
    checked
        ? setFilterStates([...filterStates, id])
        : setFilterStates(filterStates.filter((i) => i !== id));
  };

/*
  const renderMotives = () => {


    return (
        <div className="relative flex flex-col pb-8 space-y-4">
          <h3 className="font-semibold mb-2.5 mt-10">Motívy</h3>


          {
            // @ts-ignore
            productMotives&&productMotives.map((item) => (
              <div key={item.name} className="">
                <Checkbox
                    name={item.name}
                    label={item.name}
                    defaultChecked={filterStates.includes(item.id)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    onChange={(checked) => handleChangeFilters(checked, item.id)}
                />
              </div>
          ))}
        </div>
    );
  };
  */
  const renderFixedSubFilters = () => {
    type CatAttribute = {
      name: string;
      id: number;
      slug: string;
    };


      return (


                  fixedCategoryAttributes?.map((item:CatAttribute,index) => (<div key={index}>
                {/* console.log('item:',item) */ }
                      <div className="relative flex flex-col pb-8 space-y-4" >

              <h3 className="font-semibold mb-2.5 mt-10">{item.name}</h3>

                        {
                          // @ts-ignore
                          fixedCategoryAttributesValues[parseInt(item.id)].length && fixedCategoryAttributesValues[parseInt(item.id)].map((itemVal:CatAttribute,index) => (<>
                                {/* console.log('itemVal:',itemVal) */}
                                <div key={index} className="">
                                <Checkbox
                                    name={itemVal.name}
                                    label={itemVal.name}
                                    defaultChecked={
                                        // @ts-ignore
                                    filterStates.includes(itemVal.id.toString())
                                }
                                    sizeClassName="w-5 h-5"
                                    labelClassName="text-sm font-normal"
                                    onChange={(checked) => handleChangeFilters(checked, itemVal.id.toString())}
                                />
                              </div>
                              </>
                          ))
                        }
            </div>
        </div>
      ))

            );
      }
  const renderSubFilters = () => {
    type CatAttribute = {
      name: string;
      id: number;
      slug: string;
    };

        return (


                  categoryAttributes?.map((item:CatAttribute,index) => (<>
                {/* console.log('item:',item) */ }
                      <div className="relative flex flex-col pb-8 space-y-4" key={index}>

              <h3 className="font-semibold mb-2.5 mt-10">{item.name}</h3>

                        {
                          // @ts-ignore
                          categoryAttributesValues[parseInt(item.id)]?.length && categoryAttributesValues[parseInt(item.id)]?.map((itemVal:CatAttribute,index) => (<>
                                {/* console.log('itemVal:',itemVal) */}
                                <div key={index} className="">
                                <Checkbox
                                    name={itemVal.name}
                                    label={itemVal.name}
                                    defaultChecked={filterStates.includes(itemVal.id.toString())}
                                    sizeClassName="w-5 h-5"
                                    labelClassName="text-sm font-normal"
                                    onChange={(checked) => handleChangeFilters(checked, itemVal.id.toString())}
                                />
                              </div>
                              </>
                          ))
                        }
            </div>
        </>
      ))

            );
      }

    const handleSearch = (event: { target: { value: string | any[]; }; }, value: any, reason: string) => {
         console.log("onchange")
        console.log(event)
        console.log(value)
        console.log(reason)

        if (reason === 'selectOption') {
            //let navigate = useNavigate();
            //alert('vybrali ste '+value.label+' a idem smerovat do '+value.id);
//            navigate('/'+value.id);
            {
                //if (typeof window !== 'undefined') {
//                    window.location.href = '/gallery/'+value.id;
//                }
            }

        }else{
            //console.log(encodeURIComponent(event.target.value))
            //apiUrl = 'http://localhost:8003/data-api.php?tkn=hj7jyECYp2Vv4JEYBDxBCZhQt2P8zw&action=get_galleries&count='+event.target.value;
            if((event.target.value&&event.target.value.length>2)||(event.target.value.length===0)) {

                //if((!typTimer)&&(!productsAreLoading)) {
                if(!productsAreLoading) {
                    // @ts-ignore
                    clearTimeout(typTimer)
                    typTimer = setTimeout(() => {
                        console.log("going to read " + event.target.value);
                        // @ts-ignore
                        loadProducts(event.target.value,false)
                        setFiltersAreChanged(false)

                        // @ts-ignore
                        setSearchPhrase(event.target.value)

                    }, 500)
                }

            }
        };
    };
    const handleAddItem = (item: any) => {
        // Logic to add item to the cart
        console.log(`Adding item to cart: ${item.name}`);
        addItem(item)
        // Add your cart logic here, e.g., updating state or calling a service
    };

    return (
    <div className={`nc-ProductsSearchList  ${className}`} data-nc-id="ProductsSearchList">
      <Helmet>
        <title>MojaVec produkty || MojaVec </title>
      </Helmet>

      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form
            className="relative w-full"
            onSubmit={(e) => e.preventDefault()}
          >
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Napíšte čo hľadáte..."
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                  // @ts-ignore
                onChange={handleSearch}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>

      <div className="container py-16 lg:pb-28 space-y-16 lg:space-y-8">

        <main>
          {/* FILTER */}
          {/* <HeaderFilterSearchPage/> */}
          <Transition
              show={true}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
            <Nav
                className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
                containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
            >

              {
                // @ts-ignore
                productCategories&&productCategories?.map((item, index) => (
                  <NavItem2
                      key={index}
                      isActive={tabActive === item.name}
                      onClick={() => {setTabActive(item.name);navigate('/produkty/'+item.slug)}}
                  >
                    <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                      {/*
                      <span
                    className="inline-block"
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                ></span> */}
                      <span>{item.name}</span>
                    </div>
                  </NavItem2>
              ))}
            </Nav>
            {/* <TabFilters onProductsData={handleProductsData}/>  */}
          </Transition>
          {/* LOOP ITEMS */}

            <div>
                <h1 className="text-2xl 2xl:text-3xl font-semibold text-center">
                    {
                        // @ts-ignore
                        catInfo?.name
                    }
                </h1>
                <div className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-center">

                    <div dangerouslySetInnerHTML={

                        {
                            // @ts-ignore
                            __html: catInfo?.text }} />


                </div>

                <div className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-center">
                   zobrazujem {
                    // @ts-ignore
                    loadedProductsMeta?.sent_prod_count
                } z&nbsp;{
                        // @ts-ignore
                        loadedProductsMeta?.whole_prod_count
                    }
                    <br />

            </div>
            </div>

          <div className="flex flex-col lg:flex-row">



          {/* LEFT COLUMN */ }
          <div className="lg:w-1/4 xl:w-1/4 pr-4">
            <div className="divide-y divide-slate-200 dark:divide-slate-700">
              {!!fixedCategoryAttributes.length&&renderFixedSubFilters()}
              { !!categoryAttributes.length&&renderSubFilters() }
            </div>
          </div>

              {/* PRODUCT LIST */}

          <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {
              productsAreLoading ?(
              <MutatingDots
              height="100"
              width="100"
              color="#4fa94d"
              secondaryColor= '#4fa94d'
              radius='12.5'
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              />
              ):(products?.map((item, index) => (
                  // @ts-ignore
                  <ProductCard data={item} key={index} addItem={handleAddItem}/>
              )))
            }
          </div>

          </div>

          {/* PAGINATION
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />

            <ButtonPrimary loading>Ukáž viac...</ButtonPrimary>
          </div>
          */}
        </main>

      </div>
    </div>
  );
};

export default ProductsSearchList;
