import create from 'zustand';

const calculateTotals = (cart) => {
    const totalItems = cart.reduce((acc, item) => acc + item.count, 0);
    const totalPrice = cart.reduce((acc, item) => acc + item.count * item.price, 0);
    return { totalItems, totalPrice };
};

const initialCart = JSON.parse(localStorage.getItem('cart')) || [];
const initialTotals = calculateTotals(initialCart);

const useCartStore = create((set) => ({

    cart: initialCart,
    totalItems: initialTotals.totalItems,
    totalPrice: initialTotals.totalPrice,
    chosenDelivery: parseInt(localStorage.getItem('chosenDelivery'), 10) || 0,
    deliveryFee:0,
    paymentFee:0,
    chosenPayment: parseInt(localStorage.getItem('chosenPayment'), 10) || 0,
    customerData: JSON.parse(localStorage.getItem('customerData')) || {},
    isDeliveryFree: false, // initial value
    setDeliveryFree : (value) => set({ isDeliveryFree: value }),

    updateCart: (updatedCart) => {
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const totals = calculateTotals(updatedCart);
        return { cart: updatedCart, ...totals };
    },

    findItemIndex: (cart, id, size, color, selectedCut) => cart.findIndex(
        (cartItem) => cartItem.id === id && cartItem.size === size && cartItem.color === color && cartItem.selectedCut === selectedCut
    ),

    addItem: (item) => set((state) => {

        const { text, ...itemWithoutText } = item;

        const existingItemIndex = state.findItemIndex(state.cart, itemWithoutText.id, itemWithoutText.size, itemWithoutText.color, itemWithoutText.selectedCut);

        let updatedCart;
        if (existingItemIndex !== -1) {
            updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + (itemWithoutText.count || 1)
            };
        } else {
            updatedCart = [...state.cart, { ...itemWithoutText, count: itemWithoutText.count || 1 }];
        }

        return state.updateCart(updatedCart);
    }),


    removeItem: (id, size, color, selectedCut) => set((state) => {
        const newCart = state.cart.filter(item => item.id !== id || item.size !== size || item.color !== color || item.selectedCut !== selectedCut);
        return state.updateCart(newCart);
    }),

    clearCart: () => set((state) => state.updateCart([])),

    addOne: (id, size, color, selectedCut) => set((state) => {
        const existingItemIndex = state.findItemIndex(state.cart, id, size, color, selectedCut);

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + 1
            };
            return state.updateCart(updatedCart);
        }
        return state;
    }),

    removeOne: (id, size, color, selectedCut) => set((state) => {
        const existingItemIndex = state.findItemIndex(state.cart, id, size, color, selectedCut);

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            const newCount = updatedCart[existingItemIndex].count - 1;

            if (newCount > 0) {
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    count: newCount
                };
            } else {
                updatedCart.splice(existingItemIndex, 1);
            }

            return state.updateCart(updatedCart);
        }
        return state;
    }),

    setItemCount: (id, size, color, selectedCut, count) => set((state) => {
        const existingItemIndex = state.findItemIndex(state.cart, id, size, color, selectedCut);

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            if (count > 0) {
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    count
                };
            } else {
                updatedCart.splice(existingItemIndex, 1);
            }

            return state.updateCart(updatedCart);
        }
        return state;
    }),

    setChosenDelivery: (delivery) => set(() => {
        localStorage.setItem('chosenDelivery', delivery);
        return { chosenDelivery: delivery };
    }),

    clearChosenDelivery: () => set(() => {
        localStorage.removeItem('chosenDelivery');
        return { chosenDelivery: 0 };
    }),


    updateFees: (newDeliveryFee, newPaymentFee) => set(state => ({
        deliveryFee: newDeliveryFee,
        paymentFee: newPaymentFee,
    })),

    setChosenPayment: (payment) => set(() => {
        localStorage.setItem('chosenPayment', payment);
        return { chosenPayment: payment };
    }),

    clearChosenPayment: () => set(() => {
        localStorage.removeItem('chosenPayment');
        return { chosenPayment: 0 };
    }),

    setCustomerData: (data) => set(() => {
        localStorage.setItem('customerData', JSON.stringify(data));
        return { customerData: data };
    }),

    clearCustomerData: () => set(() => {
        localStorage.removeItem('customerData');
        return { customerData: {} };
    })
}));

export default useCartStore;

{/* import create from 'zustand';

const calculateTotals = (cart) => {
    const totalItems = cart.reduce((acc, item) => acc + item.count, 0);
    const totalPrice = cart.reduce((acc, item) => acc + item.count * item.price, 0);
    return { totalItems, totalPrice };
};

const initialCart = JSON.parse(localStorage.getItem('cart')) || [];
const initialTotals = calculateTotals(initialCart);

const useCartStore = create((set) => ({
    cart: initialCart,
    totalItems: initialTotals.totalItems,
    totalPrice: initialTotals.totalPrice,
    chosenDelivery: JSON.parse(localStorage.getItem('chosenDelivery')) || 1,
    isCompanyOrder: JSON.parse(localStorage.getItem('isCompanyOrder')) || false,
    chosenPayment: JSON.parse(localStorage.getItem('chosenPayment')) || 0,
    customerData: JSON.parse(localStorage.getItem('customerData')) || {},
    companyData: JSON.parse(localStorage.getItem('companyData')) || {},

    addItem: (item) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === item.id && cartItem.size === item.size
        );

        let updatedCart;
        if (existingItemIndex !== -1) {
            updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: (updatedCart[existingItemIndex].count || 1) + 1
            };
        } else {
            updatedCart = [...state.cart, { ...item, count: 1 }];
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const totals = calculateTotals(updatedCart);
        return { cart: updatedCart, ...totals };
    }),

    removeItem: (id, size) => set((state) => {
        const newCart = state.cart.filter(item => item.id !== id || item.size !== size);
        localStorage.setItem('cart', JSON.stringify(newCart));
        const totals = calculateTotals(newCart);
        return { cart: newCart, ...totals };
    }),

    clearCart: () => set(() => {
        const newCart = [];
        localStorage.setItem('cart', JSON.stringify(newCart));
        return { cart: newCart, totalItems: 0, totalPrice: 0 };
    }),

    addOne: (id, size) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === id && cartItem.size === size
        );

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + 1
            };

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        }
        return state;
    }),

    removeOne: (id, size) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === id && cartItem.size === size
        );

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            const newCount = updatedCart[existingItemIndex].count - 1;

            if (newCount > 0) {
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    count: newCount
                };
            } else {
                updatedCart.splice(existingItemIndex, 1);
            }

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        }
        return state;
    }),

    setItemCount: (id, size, count) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === id && cartItem.size === size
        );

        if (existingItemIndex !== -1 && count > 0) {
            const updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count
            };

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        } else if (existingItemIndex !== -1 && count === 0) {
            const updatedCart = state.cart.filter(
                (cartItem, index) => index !== existingItemIndex
            );

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        }
        return state;
    }),

    setChosenDelivery: (delivery) => set(() => {
        localStorage.setItem('chosenDelivery', JSON.stringify(delivery));
        return { chosenDelivery: delivery };
    }),

    clearChosenDelivery: () => set(() => {
        localStorage.removeItem('chosenDelivery');
        return { chosenDelivery: 0 };
    }),

    setIsCompanyOrder: (isCompany) => set(() => {
        localStorage.setItem('isCompanyOrder', JSON.stringify(isCompany));
        return { isCompanyOrder: isCompany };
    }),

    clearIsCompanyOrder: () => set(() => {
        localStorage.removeItem('isCompanyOrder');
        return { isCompanyOrder: false };
    }),

    setChosenPayment: (payment) => set(() => {
        localStorage.setItem('chosenPayment', JSON.stringify(payment));
        return { chosenPayment: payment };
    }),

    clearChosenPayment: () => set(() => {
        localStorage.removeItem('chosenPayment');
        return { chosenPayment: 0 };
    }),

    setCustomerData: (data) => set(() => {
        localStorage.setItem('customerData', JSON.stringify(data));
        return { customerData: data };
    }),

    clearCustomerData: () => set(() => {
        localStorage.removeItem('customerData');
        return { customerData: {} };
    }),

    setCompanyData: (data) => set(() => {
        localStorage.setItem('companyData', JSON.stringify(data));
        return { companyData: data };
    }),

    clearCompanyData: () => set(() => {
        localStorage.removeItem('companyData');
        return { companyData: {} };
    })
}));

export default useCartStore;
*/ }

{/* import create from 'zustand';

const calculateTotals = (cart) => {
    const totalItems = cart.reduce((acc, item) => acc + item.count, 0);
    const totalPrice = cart.reduce((acc, item) => acc + item.count * item.price, 0);
    return { totalItems, totalPrice };
};

const useCartStore = create((set) => ({
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    totalItems: 0,
    totalPrice: 0,

    addItem: (item) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === item.id && cartItem.size === item.size
        );

        let updatedCart;
        if (existingItemIndex !== -1) {
            updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: (updatedCart[existingItemIndex].count || 1) + 1
            };
        } else {
            updatedCart = [...state.cart, { ...item, count: 1 }];
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const totals = calculateTotals(updatedCart);
        return { cart: updatedCart, ...totals };
    }),

    removeItem: (id) => set((state) => {
        const newCart = state.cart.filter(item => item.id !== id);
        localStorage.setItem('cart', JSON.stringify(newCart));
        const totals = calculateTotals(newCart);
        return { cart: newCart, ...totals };
    }),

    clearCart: () => set(() => {
        const newCart = [];
        localStorage.setItem('cart', JSON.stringify(newCart));
        return { cart: newCart, totalItems: 0, totalPrice: 0 };
    }),

    addOne: (id, size) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === id && cartItem.size === size
        );

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: updatedCart[existingItemIndex].count + 1
            };

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        }
        return state;
    }),

    removeOne: (id, size) => set((state) => {
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === id && cartItem.size === size
        );

        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            const newCount = updatedCart[existingItemIndex].count - 1;

            if (newCount > 0) {
                updatedCart[existingItemIndex] = {
                    ...updatedCart[existingItemIndex],
                    count: newCount
                };
            } else {
                updatedCart.splice(existingItemIndex, 1);
            }

            localStorage.setItem('cart', JSON.stringify(updatedCart));
            const totals = calculateTotals(updatedCart);
            return { cart: updatedCart, ...totals };
        }
        return state;
    })
}));

export default useCartStore;


*/}

// old one:
{/*
import create from 'zustand';

const useCartStore = create((set) => ({
    cart: JSON.parse(localStorage.getItem('cart')) || [],

    addItem: (item) => set((state) => {
        // Check if the item already exists in the cart
        const existingItemIndex = state.cart.findIndex(
            (cartItem) => cartItem.id === item.id && cartItem.size === item.size
        );

        // If item exists, increment count, otherwise add it with count 1
        if (existingItemIndex !== -1) {
            const updatedCart = [...state.cart];
            updatedCart[existingItemIndex] = {
                ...updatedCart[existingItemIndex],
                count: (updatedCart[existingItemIndex].count || 1) + 1
            };
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            return { cart: updatedCart };
        } else {
            const newCart = [...state.cart, { ...item, count: 1 }];
            localStorage.setItem('cart', JSON.stringify(newCart));
            return { cart: newCart };
        }
    }),

    removeItem: (id) => set((state) => {
        const newCart = state.cart.filter(item => item.id !== id);
        localStorage.setItem('cart', JSON.stringify(newCart));
        return { cart: newCart };
    }),

    clearCart: () => set(() => {
        const newCart = [];
        localStorage.setItem('cart', JSON.stringify(newCart));
        return { cart: newCart };
    })
}));

export default useCartStore;
*/ }